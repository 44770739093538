import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../styles/BlogDetail.scss';
import Blog1 from '../components/blogDetails/Blog001';
import Blog2 from '../components/blogDetails/Blog002';
import Blog3 from '../components/blogDetails/Blog003';

// 使用iframe的方式直接把pm提供的html文件展示，但是提供的文件的样式与prd不一致，所以展示不适用这种方式，后面要上的文章多了以后需要PM提供样式完整的html文件，否则工作量会集中在文件的UI修改上

// const BlogDetail: React.FC = () => {
//   const { id } = useParams<{ id: string }>();
//   const [loading, setLoading] = useState<boolean>(true);
//   const [iframeUrl, setIframeUrl] = useState<string>('');
//   const iframeRef = useRef<HTMLIFrameElement>(null);
  
//   useEffect(() => {
//     // 根据ID确定要加载的HTML文件URL
//     console.log(id, 'id')
//     const blogUrl = `/test.html`;
//     setIframeUrl(blogUrl);
//   }, [id]);
  
//   // iframe加载完成事件处理
//   const handleIframeLoad = () => {
//     setLoading(false);
//     adjustIframeHeight();
//   };

//   // 调整iframe高度的函数
//   const adjustIframeHeight = () => {
//     if (iframeRef.current) {
//       try {
//         const iframe = iframeRef.current;
//         const iframeWin = iframe.contentWindow;
        
//         if (iframeWin) {
//           // 创建一个定时器，确保内容完全加载后再计算高度
//           setTimeout(() => {
//             // 获取文档高度的多种方式
//             const doc = iframeWin.document;
//             const body = doc.body;
//             const html = doc.documentElement;
            
//             // 取多种测量方式中的最大值
//             const height = Math.max(
//               body.scrollHeight, body.offsetHeight,
//               html.clientHeight, html.scrollHeight, html.offsetHeight
//             );
            
//             // 设置iframe高度并移除滚动条
//             iframe.style.height = `${height}px`;
            
//             // 移除iframe内部滚动条
//             if (doc.body) {
//               doc.body.style.overflow = 'hidden';
//             }
//           }, 300); // 短暂延迟以确保内容渲染完成
//         }
//       } catch (e) {
//         console.error('无法调整iframe高度:', e);
//       }
//     }
//   };
  
//   // 监听窗口大小变化，重新调整iframe高度
//   useEffect(() => {
//     const handleResize = () => {
//       adjustIframeHeight();
//     };
    
//     window.addEventListener('resize', handleResize);
    
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   return (
//     <div className="blog-detail-page">
//       <div className='blog__detail__container'>
//         {loading && <div className="loading-indicator">加载中...</div>}
        
//         <div className="blog-title">SPayLater: Bayar di Mana Pun Lebih Mudah Lebih Cepat</div>
//         <div className="blog-time">September 10,2024</div>
//         <iframe 
//           ref={iframeRef}
//           src={iframeUrl}
//           className="blog-iframe"
//           onLoad={handleIframeLoad}
//           style={{
//             width: '100%',
//             border: 'none',
//             display: loading ? 'none' : 'block',
//             overflow: 'hidden', // 确保iframe本身没有滚动条
//           }}
//           scrolling="no" // 禁用iframe滚动
//           title="博客内容"
//         />
//       </div>
//     </div>
//   );
// };

const BlobDetailMap: Record<string, ReactNode> = {
  'blog_001': <Blog1 />,
  'blog_002': <Blog2 />,
  'blog_003': <Blog3 />
}

const BlogDetail = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory()

  useEffect(() => {
    console.log(id, )
    if(id && !BlobDetailMap[id]) {
      history.push('/blog')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const renderBlog = () => {
    return BlobDetailMap[id] || null
  }

  return <>{renderBlog()}</>
}

export default BlogDetail; 