import React from "react"
import '../../styles/BlogDetail.scss'
import blog from '../../images/blog/blog_3.png'
import { Image } from "semantic-ui-react"

const Blog3 = () => {
    return <div className="blog-detail-page">
        <div className="blog__detail__container">
            <div className="blog-title">Cara Bayar QRIS Pakai SPayLater, Banyak Promo Menarik!</div>
            <div className="blog-time">March 1, 2025</div>
            <div className="blog-main-pic">
                <Image src={blog} style={{borderRadius: '20px'}} />
            </div>
            <p className="blog-paragraph">
                Apakah SPayLater bisa transaksi menggunakan QRIS? Tentu saja bisa! Pemakaian QRIS (Quick Response Code Indonesian Standard) sudah menjadi bagian dalam transaksi sehari-hari di Indonesia. Saat ini, masyarakat sudah bisa melakukan pembayaran dengan QRIS di seluruh aplikasi dari penyedia sistem pembayaran manapun.
            </p>

            <p className="blog-paragraph">
                Fitur scan QRIS dengan SPayLater hadir untuk memudahkan Anda dalam bertransaksi menggunakan sistem Beli Sekarang, Bayar Nanti dimanapun dalam kehidupan sehari-hari Anda. Hanya dengan memindai kode QRIS yang tersedia di merchant, proses transaksi menggunakan QRIS SPayLater dapat dilakukan dengan mudah.
            </p>

            <p className="blog-sub-title">
                Apa Keuntungan Bayar QRIS Pakai SPayLater?
            </p> 

            <p className="blog-paragraph">
                Berbagai keuntungan dengan metode pembayaran QRIS SPayLater yang bisa Anda nikmati, seperti:
            </p>
            
            <ol className="blog-ol">
                <li>
                    <p>Beli Sekarang, Bayar Nanti untuk Kebutuhan Sehari-hari</p>
                    <p>Pembayaran QRIS dengan SPayLater memberikan kemudahan proses pembayaran bagi Anda dalam transaksi di merchant offline sehari-hari.</p>
                </li>
                <li>
                    <p>Lebih Mudah, Lebih Cepat</p>
                    <p>Aktivasi SPayLater sangat cepat dan mudah, pastikan Anda berusia 18-65 tahun dan memiliki KTP aktif. Tanpa perlu takut kehabisan saldo, Anda bisa dengan cepat melakukan pembayaran QRIS dengan SPayLater.</p>
                </li>
                <li>
                    <p>Keamanan Terjamin</p>
                    <p>Keamanan pembayaran QRIS SPayLater juga terjamin. SPayLater oleh PT Commerce Finance yang telah berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK). ShopeePay telah berizin dan diawasi oleh Bank Indonesia. Jadi, Anda tidak perlu khawatir untuk menggunakan metode ini.</p>
                </li>
                <li>
                    <p>Banyak Promo Menarik</p>
                    <p>Nikmati berbagai promo dengan pembayaran QRIS SPayLater. Salah satunya yaitu promo SPayLater Bayar QRIS di periode 1 Januari-28 Februari 2025, di mana Anda bisa mendapatkan diskon s/d 500RB dan bebas biaya layanan (S&K berlaku) di berbagai merchant terpilih.</p>
                </li>
            </ol>

           <p className="blog-sub-title">
                Bagaimana Cara Bayar QRIS Pakai SPayLater?
            </p> 

            <p className="blog-paragraph">
                Anda bisa menggunakan SPayLater untuk bayar QRIS lewat aplikasi Shopee dan aplikasi ShopeePay.  Pertama-tama, pastikan Anda sudah men-download aplikasi Shopee atau ShopeePay yang terbaru dari Google Play Store atau Apple Store, agar seluruh fitur sudah ter-update.
            </p>
            <p className="blog-paragraph">
                Kemudian pastikan juga merchant yang Anda kunjungi memiliki opsi pembayaran dengan QRIS. Tidak seluruh merchant menerima pembayaran dengan QRIS SPayLater. Fitur QRIS SPayLater hanya dapat digunakan di merchant yang telah bekerja sama dengan SPayLater dan menyediakan QRIS dalam pilihan pembayarannya.
            </p>
            <p className="blog-paragraph">
                Selain itu, Anda bisa memilih berapa kali Anda ingin mencicil tagihan SPayLater Anda. Pastikan Anda bisa membayar seluruh tagihan yang akan datang. Jangan lupa untuk selalu bayar tagihan SPayLater tepat waktu supaya skor kredit tetap aman.
            </p>
            <ol className="blog-ol">
                <li>
                    <p>Bayar QRIS pakai SPayLater di Aplikasi Shopee</p>
                    <p>Buka aplikasi Shopee &gt; Pilih icon ‘Scan’ di sebelah ‘Saldo ShopeePay’ Anda &gt; Scan QRIS yang diberikan di kasir merchant &gt; Pilih SPayLater sebagai metode pembayaran QRIS &gt; Pilih periode cicilan yang Anda inginkan &gt; Pilih ‘Konfirmasi’</p>
                </li>
                <li>
                    <p>Bayar QRIS pakai SPayLater di Aplikasi ShopeePay</p>
                    <p>Buka aplikasi ShopeePay &gt; Pilih icon ‘QRIS’ &gt; Scan QRIS yang diberikan di kasir merchant &gt; Pilih SPayLater sebagai metode pembayaran QRIS &gt; Pilih periode cicilan yang Anda inginkan &gt; Pilih ‘Konfirmasi’</p>
                </li>
            </ol>
            <p className="blog-paragraph">
                SPayLater oleh PT Commerce Finance berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK). ShopeePay telah berizin dan diawasi oleh Bank Indonesia.
            </p>
            
            <p className="blog-paragraph">
                ----
            </p>
            <p className="blog-sub-title">
                Butuh Bantuan?
            </p>
            <p className="blog-paragraph">
            Hubungi Customer Service PT Commerce Finance di nomor (021) 8060 4253 atau email ke  customerservice@support.cmf.co.id
            </p>
        </div>
    </div>

}

export default Blog3