import React from "react"
import '../../styles/BlogDetail.scss'
import blog1 from '../../images/blog/blog_1.png'
import { Image } from "semantic-ui-react"

const Blog1 = () => {
    return <div className="blog-detail-page">
        <div className="blog__detail__container">
            <div className="blog-title">SPayLater: Bayar di Mana Pun Lebih Mudah Lebih Cepat</div>
            <div className="blog-time">March 1, 2025</div>
            <div className="blog-main-pic">
                <Image src={blog1} style={{borderRadius: '20px'}} />
            </div>
            <p className="blog-paragraph">
                SPayLater adalah metode pembayaran Beli Sekarang, Bayar Nanti yang disediakan oleh PT Commerce Finance yang bisa Anda temukan di aplikasi Shopee dan aplikasi ShopeePay.
            </p>

            <p className="blog-paragraph">
            Metode pembayaran dengan SPayLater memungkinkan Anda untuk melakukan pembelian dan menjadikan pembayarannya cicilan hingga 12 bulan, atau lebih untuk pengguna tertentu.
            </p>

            <p className="blog-paragraph">
            SPayLater tidak hanya terbatas pada pembelian produk, tetapi juga dapat digunakan untuk pembayaran tagihan bagi pengguna yang memenuhi syarat, serta dapat disambungkan sebagai metode pembayaran di berbagai aplikasi/situs online.

            </p>

            <p className="blog-paragraph">
            Selain itu, Anda juga bisa menggunakan SPayLater untuk pembayaran transaksi online maupun offline. Dengan fitur terbaru SPayLater, yaitu “Bayar Pakai QRIS”, pengguna bisa bertransaksi secara offline di berbagai toko di seluruh Indonesia yang telah bekerja sama dengan SPayLater.
            </p>

            <p className="blog-paragraph">
            Yuk, kenalan lebih jauh dengan SPayLater, simak informasi berikut ini!
            </p>

            <p className="blog-sub-title">
                Apa Keuntungan Menggunakan SPayLater?
            </p>
            
            <ol className="blog-ol">
                <li><b>Aktivasi Cepat dan Aman</b>: Prosesnya cepat dan aman. Anda cukup menepati persyaratan mudahnya, yaitu berusia 18-65 tahun, dan memiliki KTP aktif.
                </li>
                <li><b>Tenor Cicilan Fleksibel</b>: Pengguna dapat memilih berbagai periode cicilan mulai dari 1 hingga 12 bulan, bahkan 18 hingga 24 bulan untuk pengguna tertentu.
                </li>
                <li><b>Keamanan Terjamin</b>: Keamanan dalam pembayaran QRIS SPayLater juga terjamin. SPayLater oleh PT Commerce Finance yang telah berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK). ShopeePay telah berizin dan diawasi oleh Bank Indonesia. Jadi, Anda tidak perlu khawatir untuk menggunakan metode ini.
                </li>
                <li><b>Banyak Promo</b>: Anda bisa menikmati berbagai promo dengan pemakaian SPayLater. Salah satunya yaitu promo SPayLater Bayar QRIS di periode 17 September-17 November 2024, di mana Anda bisa mendapatkan bebas biaya penanganan, cicilan 0% sampai 6 bulan, hadiah mingguan emas 1gr, hingga kesempatan untuk memenangkan grand prize satu buah Toyota Avanza.
                </li>
            </ol>

           <p className="blog-sub-title">
                Bagaimana Skema Cicilan SPayLater dan Biaya yang Ada di SPayLater?
            </p> 

            <p className="blog-paragraph">
                Ada tiga tipe biaya dari SPayLater, yaitu:
            </p>
            <ol className="blog-ol">
                <li><b>Biaya Cicilan</b>: Biaya ini dimulai dari 2,95% untuk program Beli Sekarang, Bayar Nanti dalam 1 bulan dan bervariasi tergantung pada periode cicilan yang dipilih (3, 6, 12, 18, atau 24 bulan). Khusus periode 17 September - 17 November 2024, Pengguna SPayLater bisa mendapatkan promo cicilan 0% s/d 6 bulan di merchant sekitarmu.
                </li>
                <li><b>Biaya Penanganan</b>: Biaya penanganan sebesar 1% dikenakan untuk setiap transaksi yang dilakukan menggunakan SPayLater.
                </li>
                <li><b>Biaya Keterlambatan</b>: Biaya keterlambatan sebesar 5% akan dikenakan pada total tagihan jika pembayaran tidak dilakukan tepat waktu. Selain itu, keterlambatan pembayaran dapat mengakibatkan:
                    <ul>
                        <li>
                            Pembatasan akses fungsi di aplikasi dan penggunaan Voucher Shopee.
                        </li>
                        <li>
                            Peringkat kredit Anda di SLIK (Sistem Layanan Informasi Keuangan) OJK menjadi buruk yang dapat mencegah Anda untuk mendapat pembiayaan dari Bank atau perusahaan lain.
                        </li>
                        <li>
                            Akan dilakukan penagihan.
                        </li>
                    </ul>
                </li>
            </ol>
            <table className="blog-table">
                <tr className="blog-table-title">
                    <th>Periode Cicila</th>
                    <th>Biaya Penanganan</th>
                    <th>Biaya Cicilan</th>
                    <th>Biaya Keterlambatan</th>
                </tr>
                <tr className="blog-table-body">
                    <td>Cicilan 1x</td>
                    <td rowSpan={6}>1% per transaksi</td>
                    <td rowSpan={6}>Minimal 2.95% dari total pembayaran</td>
                    <td rowSpan={6}>5% per bulan dari seluruh total tagihan yang telah jatuh tempo (termasuk tagihan sebelumnya)</td>
                </tr>
                <tr className="blog-table-body">
                    <td>Cicilan 3x</td>
                </tr>
                <tr className="blog-table-body">
                    <td>Cicilan 6x</td>
                </tr>
                <tr className="blog-table-body">
                    <td>Cicilan 12x</td>
                </tr>
                <tr className="blog-table-body">
                    <td>Cicilan 18x</td>
                </tr>
                <tr className="blog-table-body">
                    <td>Cicilan 24x</td>
                </tr>
            </table>
            <p className="blog-paragraph">
                Anda akan mendapatkan notifikasi tagihan pada 10 (sepuluh) hari sebelum tanggal jatuh tempo. Rincian tagihan SPayLater akan muncul setiap tanggal 1 (satu), tanggal 15 (lima belas), tanggal 21 (dua puluh satu), atau tanggal 25 (dua puluh lima) sesuai dengan periode tagihan yang Anda pilih. Pastikan Anda melakukan pembayaran sesuai dengan tanggal periode tagihan atau tanggal jatuh tempo, ya!
            </p>
            <p className="blog-paragraph">
                Berikut contoh perhitungan tanggal pembayaran tagihan SPayLater:
            </p>
            <table className="blog-table">
                <tr className="blog-table-title">
                    <th>Periode Tanggal Rincian Tagihan</th>
                    <th>Waktu Pesanan Selesai</th>
                    <th>Periode Tanggal Jatuh Tempo</th>
                </tr>
                <tr className="blog-table-body">
                    <td>1 Agustus</td>
                    <td>1 - 31 Juli</td>
                    <td>11 Agustus</td>
                </tr>
                <tr className="blog-table-body">
                    <td>15 Agustus</td>
                    <td>15 Juli - 14 Agustus</td>
                    <td>25 Agustus</td>
                </tr>
                <tr className="blog-table-body">
                    <td>21 Agustus</td>
                    <td>21 Juli - 20 Agustus</td>
                    <td>1 September</td>
                </tr>
                <tr className="blog-table-body">
                    <td>25 Agustus</td>
                    <td>25 Juli - 24 Agustus</td>
                    <td>5 September</td>
                </tr>
            </table>
            <p className="blog-sub-title">
                Apakah SPayLater Aman untuk Digunakan?
            </p>
            <p className="blog-paragraph">
                Ya, SPayLater aman digunakan dan berkomitmen untuk mematuhi perlindungan data pengguna sebagaimana telah diatur dalam Undang-Undang Perlindungan Data Pribadi. Pengguna diberikan informasi transparan mengenai perhitungan bunga dan jumlah cicilan pada saat checkout.
            </p>
            <p className="blog-paragraph">
                SPayLater oleh PT Commerce Finance berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK). ShopeePay telah berizin dan diawasi oleh Bank Indonesia.
            </p>
            <p className="blog-paragraph">
                Yuk, aktivasi dan gunakan SPayLater Anda sekarang!
            </p>
            <p className="blog-paragraph">
                ----
            </p>
            <p className="blog-sub-title">
                Butuh Bantuan?
            </p>
            <p className="blog-paragraph">
                Hubungi Customer Service PT Commerce Finance di nomor (021) 8060 4253 atau email ke  customerservice@support.cmf.co.id
            </p>
        </div>
    </div>

}

export default Blog1