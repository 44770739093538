import React from "react"
import BlogItem from "../components/BlogItem"
import { Grid } from "semantic-ui-react"
import '../styles/Blog.scss'
import blog1 from '../images/blog/blog_1.png'
import blog2 from '../images/blog/blog_2.jpg'
import blog3 from '../images/blog/blog_3.png'
import { useHistory } from "react-router-dom"

const BlogPage = () => {
    const history = useHistory()
    const onClickItem = (target: string) => {
        history.push(`/blog/${target}`)
    }
    return  <div className="blog">
        <div className="blog__container">
            <div className="blog-main-title">Commerce Finance Blog</div>
            <div className="blog-sub-title">Dapatkan info terbaru seputar SPayLater di sini!</div>
            <Grid className="blog__content">
                <Grid.Row columns={3}>
                <div className="blog__mission">
                    <BlogItem title={'SPayLater: Bayar di Mana Pun Lebih Mudah Lebih Cepat'} content="March 1, 2025" imageUrl={blog1} onClick={onClickItem} targetName="blog_001" />
                    <BlogItem title={'Cara Mengaktifkan dan Bayar SPayLater, Bisa Pakai di Mana Pun'} content="March 1, 2025" imageUrl={blog2} onClick={onClickItem} targetName="blog_002"/>
                    <BlogItem title={'Cara Bayar QRIS Pakai SPayLater, Banyak Promo Menarik!'} content="March 1, 2025" imageUrl={blog3} onClick={onClickItem} targetName="blog_003"/>
                </div>
                </Grid.Row>
            </Grid>
        </div>
    </div>
}

export default BlogPage