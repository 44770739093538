import React from "react"
import './BlogItem.scss'
import { Image } from "semantic-ui-react"

interface BlogItemProps {
    title: string
    imageUrl: string
    content: string
    onClick: (target: string) => void
    targetName: string
} 

const BlogItem = ({ title, imageUrl, content, onClick, targetName}: BlogItemProps) => {
    const onItemClick = () => {
        onClick?.(targetName)
    }
    return (
        <div className="blog-item-container" onClick={onItemClick}>
            <div className="blog-image">
                <Image src={imageUrl} style={{width: '100%', borderRadius: '10px', height: '100%'}} />
            </div>
            <h2 className="blog-title">{title}</h2>
            <p className="blog-content">{content}</p>
            
        </div>
    )
}

export default BlogItem