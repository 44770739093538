import React from "react"
import '../../styles/BlogDetail.scss'
import blog from '../../images/blog/blog_2.jpg'
import blog2Pic1 from '../../images/blog/blog2_pic1.png'
import blog2Pic2 from '../../images/blog/blog2_pic2.png'
import blog2Pic3 from '../../images/blog/blog2_pic3.png'
import blog2Pic4 from '../../images/blog/blog2_pic4.png'
import blog2Pic5 from '../../images/blog/blog2_pic5.png'
import blog2Pic6 from '../../images/blog/blog2_pic6.png'
import blog2Pic7 from '../../images/blog/blog2_pic7.png'
import blog2Pic8 from '../../images/blog/blog2_pic8.png'
import { Image } from "semantic-ui-react"

const Blog2 = () => {
    return <div className="blog-detail-page">
        <div className="blog__detail__container">
            <div className="blog-title">Cara Mengaktifkan dan Bayar SPayLater, Bisa Pakai di Mana Pun</div>
            <div className="blog-time">March 1, 2025</div>
            <div className="blog-main-pic">
                <Image src={blog} style={{borderRadius: '20px'}} />
            </div>
            <p className="blog-paragraph">
                SPayLater adalah metode bayar paylater atau Beli Sekarang Bayar Nanti yang dapat mempermudah Anda dalam bertransaksi dengan tenor pembayaran fleksibel. Cara mengaktifkan dan menggunakan SPayLater sangat mudah dan cepat, mulai dengan persyaratan yang sederhana, yaitu berusia 18-65 tahun, dan wajib memiliki KTP yang aktif.
            </p>

            <p className="blog-paragraph">
                Selain itu, layanan SPayLater juga menawarkan berbagai promo yang menguntungkan. Khusus periode 1 Januari-28 Februari 2025, Pengguna SPayLater bisa mendapatkan promo diskon s/d 500RB dan bebas biaya layanan (S&K berlaku) di merchant offline sekitar. Anda tidak hanya bisa menggunakan SPayLater dalam aplikasi Shopee, tapi juga bisa untuk pembayaran QRIS untuk kebutuhan sehari-hari.
            </p>

            <p className="blog-paragraph">
                Saat ini SPayLater dapat digunakan di aplikasi Shopee dan aplikasi ShopeePay. Untuk mengetahui cara mengaksesnya, simak langkah-langkah berikut ini:
            </p>
            
            <ol className="blog-ol">
                <li>
                    <p>Lewat Aplikasi Shopee</p>
                    <p>Buka aplikasi Shopee &gt; Klik ikon ‘Saya’ &gt; ‘SPayLater’</p>
                    <p>Buka aplikasi Shopee &gt; Klik ikon ‘Rp’ (‘Keuangan’) &gt; Klik ‘SPayLater’</p>
                    <p>Buka aplikasi Shopee &gt; Cari ikon ‘SPayLater’ &gt;</p>
                    <p>Buka aplikasi Shopee &gt; Klik ikon ‘Lihat Semua’ &gt; Klik ‘SPayLater’</p>
                    <div className="blog-image-container">
                            <Image src={blog2Pic1} />
                            <Image src={blog2Pic2} />
                            <Image src={blog2Pic3} />
                            <Image src={blog2Pic4} />
                    </div>
                </li>
                <li>
                    <p>Lewat Aplikasi ShopeePay</p>
                    <p>Buka aplikasi ShopeePay &gt; Klik ‘SPayLater’</p>
                    <p>Buka aplikasi ShopeePay &gt; Klik ikon ‘Lihat Semua’ &gt; Klik ‘SPayLater’</p>
                    <div className="blog-image-container">
                            <Image src={blog2Pic5} />
                            <Image src={blog2Pic6} />
                    </div>
                </li>
            </ol>

           <p className="blog-sub-title">
                Bagaimana Cara Mengaktifkan SPayLater?
            </p> 

            <p className="blog-paragraph">
                SPayLater dapat diaktifkan oleh pengguna melalui aplikasi Shopee dan aplikasi ShopeePay. Berikut langkah-langkah untuk mengaktivasi SPayLater:
            </p>
            <ol className="blog-ol">
                <li>
                    <p>Kode Verifikasi (OTP)</p>
                    <p>Akses halaman SPayLater dan pilih ‘Aktifkan Sekarang’ &gt; masukkan Kode Verifikasi (OTP)* &gt; Lanjut.</p>
                </li>
                <li>
                    <p>Upload KTP</p>
                    <p>Lakukan upload foto KTP menggunakan kamera belakang dan posisikan KTP Anda pada bingkai yang tersedia &gt; Ambil Foto &gt;  cek kembali apakah foto sudah jelas. Jika sudah, pilih tombol Centang (✔). Namun, jika foto masih buram/kurang jelas, silakan pilih tombol (&lt;) untuk kembali ke pengambilan foto &gt; cek Nama, NIK dan Tanggal Lahir yang sudah terisi otomatis &gt; pilih Konfirmasi &gt; masukkan Informasi Tambahan  Lanjut.</p>
                </li>
                <li>
                    <p>Verifikasi SPayLater</p>
                    <p>Lakukan Verifikasi Wajah dengan memilih ikon Mulai Verifikasi Wajah &gt; arahkan wajah Anda ke dalam bingkai yang ditunjukkan dan pastikan Anda mengambil gambar di ruangan yang terang.</p>

                </li>
            </ol>
            <p className="blog-paragraph">
                Jika berhasil, Anda akan menerima notifikasi bahwa SPayLater Anda sedang diproses. Aplikasi aktivasi SPayLater akan diperiksa oleh tim terkait dalam waktu 48 jam.
            </p>
            <p className="blog-paragraph">
                Jika permohonan aktivasi disetujui, Anda akan menerima pop-up notifikasi yang berisi informasi bahwa aktivasi berhasil serta informasi mengenai limit umum dan limit cicilan yang diperoleh untuk melakukan transaksi menggunakan SPayLater.
            </p>
            <p className="blog-sub-title">
                Bagaimana Cara Melakukan Pembayaran Menggunakan SPayLater?
            </p>
            <p className="blog-bold-p">
                Transaksi Online melalui Aplikasi Shopee
            </p>
            <p className="blog-paragraph">
                Pada saat pembayaran online, Anda dapat menggunakan SPayLater dengan langkah berikut: Pilih ‘Metode Pembayaran’ &gt; pilih ‘SPayLater sebagai Metode Pembayaran’ &gt; pilih ‘Konfirmasi’ &gt; pilih ‘Buat Pesanan’ &gt; masukkan PIN Anda.
            </p>
            <p className="blog-paragraph">
                Apabila saat check out limit SPayLater Anda kurang dari jumlah total pembayaran, maka Anda dapat membayar sebagian dengan SPayLater dan sisanya dengan Metode Pembayaran lain.
            </p>
            <p className="blog-paragraph">
                Berikut langkahnya: Pilih Metode Pembayaran &gt; pilih SPayLater sebagai Metode Pembayaran &gt; pilih Konfirmasi &gt; pilih Buat Pesanan &gt; pilih Opsi Pembayaran Tambahan &gt; pilih Konfirmasi &gt; masukkan PIN ShopeePay Anda &gt; Lakukan sisa pembayaran dengan Metode Pembayaran lain yang dipilih.
            </p>
            <p className="blog-bold-p">
                Transaksi Offline melalui Aplikasi Shopee atau ShopeePay
            </p>
            <p className="blog-paragraph">
            Pada transaksi offline, Anda dapat membayar QRIS dengan SPayLater di aplikasi Shopee dengan langkah berikut: Buka beranda aplikasi Shopee &gt; Pilih ikon ‘Scan QR’ disamping saldo ShopeePay &gt; Scan QRIS &gt; Pastikan nominal sudah sesuai &gt; Pilih ‘SPayLater’ sebagai Metode Pembayaran &gt; Pilih tenor cicilan yang Anda mau &gt; Klik ‘Bayar Sekarang’
            </p>
            <p className="blog-paragraph">
            Apabila Anda membayar QRIS dengan SPayLater di aplikasi ShopeePay, ikuti langkah berikut: Buka beranda aplikasi ShopeePay &gt; Pilih ikon ‘Kode Bayar’ atau ‘Bayar’ &gt; Scan QRIS &gt; Pastikan nominal sudah sesuai &gt; Pilih ‘SPayLater’ sebagai Metode Pembayaran &gt; Pilih tenor cicilan yang Anda mau &gt; Klik ‘Bayar Sekarang’
            </p>
            <p className="blog-paragraph">
                Beberapa persyaratan yang harus Anda ketahui seputar pembayaran menggunakan SPayLater:
            </p>
            <ol className="blog-ol">
                <li>Jika harga produk di bawah Rp50.000, maka hanya ada pilihan Beli Sekarang dan Bayar Nanti . </li>
                <li>Jika limit Anda sudah habis terpakai, pilihan Beli Sekarang dan Bayar Nanti  tidak akan terlihat.</li>
                <li>Jika limit Anda sudah habis terpakai dan limit cicilan di bawah Rp50.000, maka Metode Pembayaran SPayLater tidak dapat dipilih (Berwarna abu-abu).</li>
            </ol>
            <p className="blog-paragraph">
            Limit SPayLater Anda akan otomatis berkurang ketika pembayaran berhasil diverifikasi dan tagihan SPayLater akan muncul setelah pesanan selesai. Anda dapat melakukan check out pesanan  maupun melakukan pembayaran offline dengan SPayLater selama masih memiliki limit pinjaman dan tidak terdapat keterlambatan pembayaran produk pinjaman lainnya di aplikasi Shopee dan aplikasi ShopeePay. Dalam Perjanjian Kredit, Jumlah Pokok Fasilitas Pinjaman sudah termasuk Biaya Penanganan dan Biaya Cicilan.
            </p>
            <p className="blog-sub-title">
                Kapan Harus Membayar Tagihan SPayLater?
            </p>
            <p className="blog-paragraph">
            Anda akan mendapatkan notifikasi tagihan pada 10 (sepuluh) hari sebelum tanggal jatuh tempo. Rincian tagihan SPayLater akan muncul setiap tanggal 1 (satu), tanggal 15 (lima belas), tanggal 21 (dua puluh satu), atau tanggal 25 (dua puluh lima) sesuai dengan periode tagihan yang Anda pilih. Pastikan Anda melakukan pembayaran sesuai dengan tanggal periode tagihan atau tanggal jatuh tempo.
            </p>
            <p className="blog-paragraph">
            Contoh perhitungan tanggal pembayaran tagihan SPayLater:
            </p>  
            <table className="blog-table">
                <tr className="blog-table-title">
                    <th>Periode Tanggal Rincian Tagihan</th>
                    <th>Waktu Pesanan Selesai</th>
                    <th>Periode Tanggal Jatuh Tempo</th>
                </tr>
                <tr className="blog-table-body">
                    <td>1 Agustus</td>
                    <td>1 - 31 Juli</td>
                    <td>11 Agustus</td>
                </tr>
                <tr className="blog-table-body">
                    <td>15 Agustus</td>
                    <td>15 Juli - 14 Agustus</td>
                    <td>25 Agustus</td>
                </tr>
                <tr className="blog-table-body">
                    <td>21 Agustus</td>
                    <td>21 Juli - 20 Agustus</td>
                    <td>1 September</td>
                </tr>
                <tr className="blog-table-body">
                    <td>25 Agustus</td>
                    <td>25 Juli - 24 Agustus</td>
                    <td>5 September</td>
                </tr>
            </table>
            <p className="blog-sub-title">
                Bagaimana Cara Melihat dan Membayar Tagihan SPayLater?
            </p>
            <ol className="blog-ol">
                <li>
                    <p>Cara Melihat Tagihan SPayLater</p>
                    <p>Anda dapat melihat tagihan SPayLater dengan memilih tab Saya pada halaman utama aplikasi Shopee &gt; pilih SPayLater &gt; pilih Tagihan Saya. Daftar tagihan yang Belum Lunas dan Lunas setiap bulannya akan ditampilkan pada halaman ini. </p>
                    <p>Apabila Anda ingin melihat rincian tagihan SPayLater yang Lunas dan Belum Lunas, Anda bisa memilih tab ‘Belum Lunas’ atau ‘Lunas’, kemudian pilih bulan yang ingin Anda cek.</p>
                    <div className="blog-image-container">
                            <Image src={blog2Pic7} width={200} />
                    </div>
                </li>
                <li>
                    <p>Cara Bayar Tagihan SPayLater</p>
                    <p>Untuk pelunasan tagihan SPayLater, Anda dapat membayar tagihan melalui ShopeePay, Alfamart, Indomaret dan Transfer Bank melalui berbagai Bank.</p>
                    <p>Pada halaman ‘Tagihan Saya’, pilih ‘Bayar Sekarang’ &gt; Pilih ‘Metode Pembayaran’ &gt; Pilih ‘Konfirmasi’ &gt; Pilih ‘Bayar Sekarang’ &gt; Lakukan pembayaran.</p>
                    <div className="blog-image-container">
                            <Image src={blog2Pic8} />
                    </div>
                </li>
            </ol>

            <p className="blog-paragraph">
                SPayLater oleh PT Commerce Finance berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK). ShopeePay telah berizin dan diawasi oleh Bank Indonesia.
            </p>
            <p className="blog-paragraph">
                ----
            </p>
            <p className="blog-sub-title">
                Butuh Bantuan?
            </p>
            <p className="blog-paragraph">
            Hubungi Customer Service PT Commerce Finance di nomor (021) 8060 4253 atau email ke  customerservice@support.cmf.co.id
            </p>
        </div>
    </div>

}

export default Blog2