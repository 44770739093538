import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from '../images/company-logo-removebg.png';
import splLogo from '../images/sPayLater-logo.png';
import '../styles/Header.scss';

function Header() {
  const handleClickContactUs = () => {
    const elem = document.querySelector('.footer');
    elem?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="header">
      <Menu fluid borderless widths={5}>
        <Menu.Item name="Logo" active={false} as={Link} to={'/'}>
          <div className="imgContainer">
            <img src={logo} alt="Commerce Finance" className="logo" />
            <img src={splLogo} alt="Commerce Finance" className="logo" />
          </div>
        </Menu.Item>
        <Menu.Item
          name="tentang kami"
          active={false}
          as={Link}
          to={'/about-us'}
        />
        <Menu.Item name="produk" active={false} as={Link} to={'/product'} />
        <Menu.Item name="blog" active={false} as={Link} to={'/blog'} />
        <Menu.Item
          name="hubungi"
          active={false}
          onClick={handleClickContactUs}
        />
      </Menu>
    </div>
  );
}

export default Header;
